import doc from "../../assets/icons/filetypes/doc.svg";
import pdf from "../../assets/icons/filetypes/pdf.svg";
import ppt from "../../assets/icons/filetypes/ppt.svg";
import txt from "../../assets/icons/filetypes/txt.svg";
import xls from "../../assets/icons/filetypes/xls.svg";

export const queryKeys = {
  settingsV2: "settings-v2",
  createInvitations: "create-invitations",
  invitations: "invitations",
  accounts: "accounts",
  documents: "documents",
  opportunities: "opportunities",
  opportunity: "opportunity",
  discoveryQuestions: "discovery-questions",
  discoveryQuestion: "discovery-question",
  objections: "objections",
  objection: "objection",
  caseStudies: "case-studies",
  caseStudy: "case-study",
  autoCompleteSearch: "search",
  metaData: "metadata",
  battleCard: "battle-card",
  battleCards: "battle-cards",
  genericResources: "generic-resources",
  genericResource: "generic-resource",
  genericResourceTypes: "generic-resource-types",
  home: "home",
  assetcount: "asset-count",
  relationshipParameters: "relationship-parameters",
  assetType: "asset-type",
  solutionParameterTags: "solution-parameter-tags",
  teams: "teams",
  solutions: "solutions",
  templates: "templates",
  analytics: "analytics",
  apps: "apps",
  appConfig: "app-config",
  appCrmObjects: "app-crm-objects",
  appFields: "app-fields",
  solutionAssetsType: "solution-assets-type",
  solutionAssets: "solution-assets",
  solutionAsset: "solution-asset",
  shareableResPublicData: "shareable-public-data",
  campaigns: "campaigns",
  campaignUser: "campaign-user",
  campaignUserCompany: "campaign-user-company",
  campaignEmailPrompts: "campaign-email-prompts",
  variables: "variables",
};

export const SIGNIN_METHODS = {
  GOOGLE: "google",
  MAGIC_LINK: "magic-link",
};

export const fileTypeIconMap = {
  jpg: txt,
  jpeg: txt,
  png: txt,

  txt: txt,
  pdf: pdf,

  doc: doc,
  docx: doc,
  odt: doc,

  xls: xls,
  xlsx: xls,
  csv: xls,
  ods: xls,

  ppt: ppt,
  pptx: ppt,
  odf: ppt,
};
